<template>
  <form class="loginDiv" method="post" @submit.prevent="handleSubmit">
    <div class="background-image-div" :style="{ backgroundImage: 'url(' + require('@/assets/images/vasion-logo-texture.png') + ')'}">
      <div class="innerLoginDiv">
        <div class="w100 icon-fill">
          <VasionWordmark class="vasion-wordmark" />
        </div>
        <div class="loginSpacer32" />
        <h4 v-if="loginError" id="invalid-password-notification">
          {{ errorMessage }}
        </h4>
        <h4 v-else id="processing-notification">
          Processing SSO response...
        </h4>
        <div class="loginSpacer24" />
        <div class="login-button-div">
          <VasionButton
            id="login-submit"
            :isFullWidth="true"
            :classProp="'primary'"
            :isDisabled="!loginError"
            @vasionButtonClicked="retryClick"
          >
            <span>Retry</span>
          </VasionButton>
        </div>
        <div class="loginSpacer32" />
        <div class="loginSpacer24" />
      </div>
      <VasionMSAModal
        id="vasion-msa-modal"
        :showModal="showMSA"
        @yesButtonClick="msaModalYes"
        @noButtonClick="msaModalNo"
      />
    </div>
  </form>
</template>

<script>
import VasionMSAModal from '@/components/shared/VasionMSAModal.vue'

export default {
  name: 'SAML',
  components: {
    VasionMSAModal,
  },
  data: function () {
    return {
      loginError: false,
      showMSA: false,
    }
  },
  computed: {
    backgroundUrl() {
      // eslint-disable-next-line
      return require('@/assets/images/vasion-logo-texture.png')
    },
    defaultApp() { return this.$store.state.admin.defaultApp },
    defaultAppLocation() {
      let location = ''
      switch (this.defaultApp) {
        case 'admin':
          location = 'TheEmptyAdmin'
          break
        case 'capture':
          location = 'StartScan'
          break
        case 'signature':
          location = 'TheSignatureInbox'
          break
        case 'reports':
          location = 'TheEmptyReport'
          break
        case 'storage':
          location = 'VaultFolders'
          break
        case 'workspace':
          this.$store.dispatch('mainViews/changeMainNav', 'my-tasks-pending')
          location = 'TheWorkspaceHome'
          break;
        case 'forms':
          location = 'AllForms'
          break;
        case 'templates':
          this.$store.dispatch('common/getEmailTemplates')
          location = 'AllEmailTemplates'
          break;
        case 'automation':
          location = 'TheAutomationHome'
          break;
        case 'workflow':
          this.$store.dispatch('workflow/selectWorkflow', 0)
          location = 'AllWorkflows'
          break;
        default:
          location = 'TheWorkspaceHome'
          this.$store.dispatch('mainViews/changeMainNav', 'home')
          break
      }
      return location
    },
  },
  created: async function () {
    this.errorMessage = 'An error occurred processing the SSO response.'

    if (this.$route.query.error !== null && this.$route.query.error !== undefined) {
      this.errorMessage = this.$route.query.error
    }

    if (this.$route.query.token === null || this.$route.query.token === undefined || this.$route.query.token === '') {
      this.loginError = true
      return
    }

    await this.$store.dispatch('common/resetAxiosDefaultBaseURL')
    await this.$store.dispatch('common/signInWithToken', this.$route.query.token)
    if (this.$store.state.common.isSignedIn === false) {
      this.loginError = true
      return
    }

    if (this.$store.state.common.isMSAApproved === false && this.$store.state.common.userID !== 1) {
      this.errorMessage = 'MSA has not been approved.'
      this.loginError = true
      if (this.$store.state.common.userIsAdmin)
        this.showMSA = true
      
      return
    }

    this.routeFromSuccessfulLogin()
  },
  methods: {
    async msaModalNo()
    {
      this.loginError = true
      this.showMSA = false
      await this.$store.dispatch('common/signOut')
      this.$router.push({ name: 'TheLogin' })
    },
    async msaModalYes()
    {
      this.showMSA = false
      this.routeFromSuccessfulLogin()
    },
    async retryClick() { 
      await this.$store.dispatch('common/signOut')
      this.$router.push({ name: 'TheLogin' }) 
    },
    async routeFromSuccessfulLogin() {

      await this.$store.dispatch('admin/getAllUserSettings')
      await this.$store.dispatch('workflow/setCustomFolderView', this.$store.state.workflow.customFolderViewSaved)

      if (this.$route && this.$route.query && this.$route.query.redirect && this.$route.query.redirect !== '') {
        this.$router.push({ path: this.$route.query.redirect })
      } else if (this.defaultApp !== '') {
        this.$router.push({ name: this.defaultAppLocation })
      } else {
        this.$router.push({ name: 'TheVasionHome' })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
@import '@/assets/css/light-theme-system-palette.scss';

.background-image-div {
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-fill {
  fill: $white;
}

.innerLoginDiv {
  height: 400px;
  width: 85%;
  max-width: 420px;
}
#invalid-password-notification {
  color: red;
  text-align: center;
  font-size: 17px;
}

#processing-notification {
  color: white;
  text-align: center;
  font-size: 17px;
}

.login-button-div {
  position: relative;
  left: -7px;
  width: 103.5%;
}

.loginDiv {
  width: calc(100vw);
  background-color: #252626;
  height: calc(100vh);
}

.loginSpacer24 {
  height: 24px;
}

.loginSpacer32 {
  height: 32px;
}

.vasion-wordmark {
  width: 100%;
  position: relative;
  right: 8px;
}

.w100 {
  width: 100%;
  text-align: center;
}

</style>
